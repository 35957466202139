//Recruitments Setion
import { useEffect } from "react";
import Sticky from "react-stickynode";
import Heading from "../../Components/Heading";
import { ThemeProvider } from "styled-components";
import { mainTheme } from "../../theme/main";
import { ResetCSS } from "../../assets/css/style";
import { DrawerProvider } from "../../contexts/DrawerContext";
import Navbar from "../../Components/Layout/PagesNavbar";
import Link from "../../Components/CustomLink/CustomLink";
import DrawerSection from "../../Components/Layout/DrawerSection";
import Container from "../../Components/UI/Container";
import Footer from "../../Components/Layout/Footer";
import { TableWrapper } from "../../assets/css/Custom/AcademicStyle";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import {
  GlobalStyle,
  CharityWrapper,
  ContentWrapper,
} from "../../assets/css/Main/main.style";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { scrollToContent } from "../../functions/scroll.js";

function RTI() {
  useEffect(() => {
    scrollToContent(0);
  }, []);

  const { t } = useTranslation();

  return (
    <ThemeProvider theme={mainTheme}>
      <Helmet>
        <title>
          {t("Right_To_Information")} | {t("NIT Jamshedpur")} |
        </title>
        <meta
          name="description"
          content="Right to Information: Filing of Application RTI Application Form: A person seeking information may file an application on form either in writing or by e-mail giving particulars of the information being sought...."
        />
        <meta
          name="keywords"
          content="nit jamshedpur,nit jsr,nit,jamshedpur,goals, map,reach, cc,guest,objectives,technology,Director,,stafflist,facility,rti, right to informations,output Indicators,Indicators..."
        />
      </Helmet>
      <ResetCSS />
      <GlobalStyle />
      <CharityWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <Navbar />
        </Sticky>
        <DrawerProvider>
          <DrawerSection />
        </DrawerProvider>
        <ContentWrapper>
          <Container className="mt-5 mb-5 py-5">
            <h1 className="text-center">
              <strong>{t("Right_To_Information")}</strong>
            </h1>
            <strong>
              <TableWrapper className="table-responsive mt-5">
                <table className="table mb-5">
                  <thead>
                    <tr>
                      <th>{t("Position")}</th>
                      <th>{t("Name")}</th>
                      <th>{t("Phone")}</th>
                      <th>{t("Email")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{t("rti_pos_4")} </td>
                      <td>
                        {t("rti_name_1")}
                        <br />
                        <small>
                          {t("prof")}
                          <br />
                          {t("me_dept")}
                        </small>
                      </td>
                      <td> </td>
                      <td>
                        <Link to="mailto:nodal.rti@nitjsr.ac.in">
                          nodal.rti@nitjsr.ac.in
                        </Link>
                      </td>
                    </tr>

                    <tr>
                      <td>{t("rti_pos_1")} </td>
                      <td>
                        {t("rti_name_3")}
                        <br />
                        NIT Jamshedpur
                        <small>
                          {/* {t("prof")} */}

                          <br />
                          {/* {t("prod_dept")} */}
                        </small>
                      </td>
                      <td>0657-2373-774 </td>
                      <td>
                        <Link to="mailto:registrar@nitjsr.ac.in">
                          registrar@nitjsr.ac.in
                        </Link>
                      </td>
                    </tr>

                    <tr>
                      <td>{t("rti_pos_2")}</td>
                      <td>
                        {t("rti_name_2")}
                        <br />
                        <small>
                          {t("prof")}
                          <br />
                          {t("ce_dept")}
                        </small>
                      </td>
                      <td>0657-2472-235</td>

                      <td>
                        <Link to="mailto:cpio@nitjsr.ac.in">
                          cpio@nitjsr.ac.in
                        </Link>
                      </td>
                    </tr>

                    <tr>
                      <td>{t("rti_pos_3")}</td>
                      <td>
                        {t("rti_name_4")}
                        <br />

                        {/* <small>
                          {t("prof")}
                          <br />
                          {t("me_dept")}
                        </small> */}
                      </td>
                      <td></td>
                      <td>
                        {/* <Link to="mailto:cpio@nitjsr.ac.in">
                          cpio@nitjsr.ac.in
                        </Link> */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TableWrapper>
            </strong>
            <Heading
              className="mt-5 bg-dark"
              content={t("rti_head_1")}
              style={{
                fontSize: "1.5em",
                marginBottom: "20px",
                marginTop: "13px",
                fontWeight: "500",
                background: "#274187",
                color: "#fff",
                padding: "1% 1% 1% 3%",
              }}
            />
            <div className="px-3">
              <p>
                <i className="fas fa-dot-circle"></i> {t("rti_file_1")}
              </p>
              <p>
                <i className="fas fa-dot-circle"></i> {t("rti_file_2")}
              </p>
              <p>
                <i className="fas fa-dot-circle"></i> {t("rti_file_3")}
              </p>
            </div>
            <Heading
              className="mt-5 bg-dark"
              content={t("rti_head_2")}
              style={{
                fontSize: "1.5em",
                marginBottom: "20px",
                marginTop: "13px",
                fontWeight: "500",
                background: "#274187",
                color: "#fff",
                padding: "1% 1% 1% 3%",
              }}
            />
            <div className="px-3">
              <p>
                <i className="fas fa-dot-circle"></i> {t("imp_info_1")}
              </p>
              <p>
                <i className="fas fa-dot-circle"></i> {t("imp_info_2")}
              </p>
              <p>
                <i className="fas fa-dot-circle"></i> {t("imp_info_3")}
              </p>
              <p>
                <i className="fas fa-dot-circle"></i> {t("imp_info_4")}
              </p>
            </div>
            <br />
            <Heading
              className="mt-5 bg-dark"
              content={"Department wise HODs Tenure"}
              style={{
                fontSize: "1.5em",
                marginBottom: "20px",
                marginTop: "13px",
                fontWeight: "500",
                background: "#274187",
                color: "#fff",
                padding: "1% 1% 1% 3%",
              }}
            />
            <Link to="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/civil%20engineering.pdf">
              <i className="fas fa-file-alt"> Civil Engineering</i>
            </Link>
            <br />
            <Link to="https://nitjsr.ac.in/backend/uploads/departments/COMPUTER%20CENTRE.pdf">
              <i className="fas fa-file-alt"> Computer Center</i>
            </Link>
            <br />
            <Link to="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/cse%20hod%20tenure.pdf">
              <i className="fas fa-file-alt"> Computer Science and Engineering</i>
            </Link>
            <br />
            <Link to="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/Department%20of%20Chemistry.pdf">
              <i className="fas fa-file-alt"> Chemistry</i>
            </Link>
            <br />
            <Link to="https://nitjsr.ac.in/backend/uploads/departments/Department%20of%20Electrical%20Engineering.pdf">
              <i className="fas fa-file-alt"> Electrical Engineering</i>
            </Link>
            <br />
            <Link to="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/electronics%20and%20communication.pdf">
              <i className="fas fa-file-alt"> Electronics and Communication Engineering</i>
            </Link>
            <br />
            <Link to="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/department%20of%20HSSM.pdf">
              <i className="fas fa-file-alt"> Humanities, Social Sciences and Management</i>
            </Link>
            <br />
            <Link to="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/the%20department%20of%20mathematics%20.pdf">
              <i className="fas fa-file-alt"> Mathematics</i>
            </Link>
            <br />
            <Link to="https://nitjsr.ac.in/backend/uploads/departments/Mechanical%20engineering.pdf">
              <i className="fas fa-file-alt"> Mechanical Engineering</i>
            </Link>
            <br />
            <Link to="https://nitjsr.ac.in/backend/uploads/departments/DEPARTMENT%20OF%20METALLURGICAL%20AND%20MATERIALS%20ENGINEERING.pdf">
              <i className="fas fa-file-alt"> Metallurgical and Materials Engineering</i>
            </Link>
            <br />
            <Link to="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/from%20Department%20Of%20Production%20and%20Industrial%20Engineering.pdf">
              <i className="fas fa-file-alt"> Production and Industrial Engineering</i>
            </Link>
            <br />
            <Link to="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/department%20of%20physics%20.pdf">
              <i className="fas fa-file-alt"> Physics</i>
            </Link>
            <Heading
              className="mt-5 bg-dark"
              content={"Pay Structure of Institute Employees"}
              style={{
                fontSize: "1.5em",
                marginBottom: "20px",
                marginTop: "13px",
                fontWeight: "500",
                background: "#274187",
                color: "#fff",
                padding: "1% 1% 1% 3%",
              }}
            />
            <Link to="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/Pay%20Structure%20of%20Institute%20Employees%20July%202023.pdf">
              <i className="fas fa-file-alt"></i> July 2023
            </Link>
            <br />

            <Link to="https://nitjsr.ac.in/backend/uploads/rti/new/List%20of%20employees%20with%20gross%20monthly%20remuneration%20August%202024.pdf">
              <i className="fas fa-file-alt"></i> August 2024
            </Link>
            <br />
            <Heading
              className="mt-5 bg-dark"
              content={"RTI Quarterly Return"}
              style={{
                fontSize: "1.5em",
                marginBottom: "20px",
                marginTop: "13px",
                fontWeight: "500",
                background: "#274187",
                color: "#fff",
                padding: "1% 1% 1% 3%",
              }}
            />
            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/quarterly%20report%20April%20-%20June%202024.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> 1st Quarter (April-June)
              2024-2025
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/Ist%20Quarter%20(April-June)2023-2024.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> 1st Quarter (April-June)
              2023-2024
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/4th%20Quarter%20(Jan-Mar)2022-2023.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> 4th Quarter (Jan-Mar)
              2022-2023
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/3rd%20Quarter%20(Oct-Dec)2022-2023.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> 3rd Quarter (Oct-Dec)
              2022-2023
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/2nd%20Quarter%20(July-Sept)2022-2023.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> 2nd Quarter (July-Sept)
              2022-2023
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/RTI%20QUATERLY%20RETURN_April-June%202022-2023.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> 1st Quarter (April-June)
              2022-2023
            </a>
            <Heading
              className="mt-5 bg-dark"
              content={"Important Documents"}
              style={{
                fontSize: "1.5em",
                marginBottom: "20px",
                marginTop: "13px",
                fontWeight: "500",
                background: "#274187",
                color: "#fff",
                padding: "1% 1% 1% 3%",
              }}
            />


            {/* <a
                href="https://nitjsr.ac.in/backend/uploads/recruitments/National_Institute_of_Technology_NIT_Jamshedpur%20(1).pdf"
                rel="noreferrer"
                target={"_blank"}
              >
                {"Link"}
              </a> */}
            {/* /home/nimcet/webteam/backend/uploads/rti/new/Office Order No-209-2023, Committee constituted for PIOs-FAAS under RTI Act.2005.PDF */}
            <a
              href="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/Tentative%20Annual%20Plan%20for%20the%20Financial%20Year%202023-24%20(2).pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> Annual Budget 2023-24
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/ABSTRACT%20OF%20QUARTERLY%20RETURN%202023-24.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> Abstract of Quarterly Return
              2023-24
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/ABSTRACT%20OF%20QUARTERLY%20RETURN%202022-23.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> Abstract of Quarterly Return
              2022-23
            </a>
            <br />


            <a
              href="https://nitjsr.ac.in//backend/uploads/rti/new/Office Order No-209-2023, Committee constituted for PIOs-FAAS under RTI Act.2005.PDF"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> Committee constituted for
              PIOs/FAAS under RTI Act.2005
            </a>
            <br />

            <a
              href="https://nitjsr.ac.in//backend/uploads/rti/new/Office Order No.-210-2023, Committee constituted for Key stake holders regarding RIT Act.2005.PDF"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i>
              Committee constituted for Key stake holders regarding RTI Act.2005
            </a>
            <br />

            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/Citizen's Charter Annexure 1.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> Citizen Charter
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/Annual%20Report%20NITJSR%202022-23%20(English)-164-171.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> CAG and PAC Paras
            </a>
            <br />

            <a
              href="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/CVC%20GUIDELINE%20FOR%20TRASNFSER%20POLICY.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> CVC Guidelines on Transfer
              Policy
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/details%20of%20contract%20entered%20into%202023-24.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> Details of Contract entered
              into 2023-24
            </a>
            <br />






            <a
              href="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/IMG_20230816_122323.jpg"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> Earlier CPIO & FAAs from
              1.1.2015
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/FREQUENTLY%20ASKED%20QUESTIONS%20(FAQs)%20ON%20RTI.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> Frequently Asked Questions
              (FAQs)
            </a>
            <br />



            {/* //////////////////////// */}


            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/List%20of%20completed%20Projects-Schemes%202023-24.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> List of completed
              Projects-Schemes 2023-24
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/list%20of%20earlier%20directors%20NIT%20Jamshedpur.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> LIST OF EARLIER DIRECTORS OF
              NIT JAMSHEDPUR
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/departments/Faculty%20members%20attended%20various%20academic%20conferences%202023-24.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> List of faculty members who
              attended various academic conferences during 2023-24
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/uploads_2023/rti/Parliamentary%20queries%20and%20Replies.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> Parliamentary queries and
              Replies
            </a>
            <br />
            <a
              href="https://rti.gov.in/rti-act.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> RTI ACT 2005 Gazette (English)
            </a>
            <br />

            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/RTI%20ACT_Hindi%20version.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> RTI ACT 2005 Gazette (Hindi)
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/Suo%20Moto%20Disclosure%202023-24.pdf "
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> SUO MOTO DISCLOSURE/RTI Manuals
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/TP_AuditedReport_NIT_Jamshedpur_2022-23%20(1).pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> Third Party Audit Report
              (2022-23)
            </a>
            <br />
            <a
              href="https://nitjsr.ac.in/backend/uploads/rti/new/Telephone%20Directory.pdf"
              rel="noreferrer"
              target={"_blank"}
            >
              <i className="fas fa-file-alt"></i> Telephone Directory
            </a>
            <br />
            <Heading
              className="mt-5 bg-dark"
              content={"Annual Reports"}
              style={{
                fontSize: "1.5em",
                marginBottom: "20px",
                marginTop: "13px",
                fontWeight: "500",
                background: "#274187",
                color: "#fff",
                padding: "1% 1% 1% 3%",
              }}
            />

            <TableContainer
              component={Paper}
              sx={{ width: "100%", marginBottom: "3%", overflowX: "auto" }}
            >
              <Table
                sx={{
                  minWidth: "760px",
                  borderCollapse: "collapse",
                  margin: "20px auto",
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "#274187",
                        color: "white",
                        fontWeight: "bold",
                        padding: "1.5%",
                        border: "1px solid #ccc",
                        textAlign: "left",
                        fontSize: "15px",
                      }}
                    >
                      {t("Year")}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "#274187",
                        color: "white",
                        fontWeight: "bold",
                        padding: "1.5%",
                        border: "1px solid #ccc",
                        textAlign: "left",
                        fontSize: "15px",
                      }}
                    >
                      {t("Reports in English")}
                    </TableCell>
                    <TableCell
                      sx={{
                        backgroundColor: "#274187",
                        color: "white",
                        fontWeight: "bold",
                        padding: "1.5%",
                        border: "1px solid #ccc",
                        textAlign: "left",
                        fontSize: "15px",
                      }}
                    >
                      {t("Reports in Hindi")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow sx={{ border: "1px solid #ccc" }}>
                    <TableCell
                      sx={{
                        padding: "1.5%",
                        border: "1px solid #ccc",
                        textAlign: "left",
                        fontSize: "15px",
                      }}
                    >
                      2022-2023
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "1.5%",
                        border: "1px solid #ccc",
                        textAlign: "left",
                        fontSize: "15px",
                      }}
                    >
                      <a
                        href="https://nitjsr.ac.in/backend/uploads/annual_report/Annual%20Report%20NITJSR%202022-23%20(English).pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        PDF
                      </a>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "1.5%",
                        border: "1px solid #ccc",
                        textAlign: "left",
                        fontSize: "15px",
                      }}
                    >
                      <a
                        href="https://nitjsr.ac.in/backend/uploads/annual_report/Annual%20Report%20NITJSR%202022-23%20(Hindi).pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        PDF
                      </a>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </ContentWrapper>
        <Footer />
      </CharityWrapper>
    </ThemeProvider>
  );
}

export default RTI;
